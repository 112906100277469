<template>
  <div>
    图片管理
  </div>
</template>

<script>
export default {
  name: "image-manager",
  data() {
    return {};
  },
};
</script>

<style>
</style>